import { string, object } from "yup";

const nameValidation = (
  field: string,
  t: any,
  minLength: number = 3,
  maxLength: number = 250
) => {
  return string()
    .required(
      t("validationMessages.fieldIsRequired", {
        field,
      })
    )
    .max(
      maxLength,
      t("validationMessages.maxLengthValidation", {
        field,
        validLength: maxLength,
      })
    )
    .min(
      minLength,
      t("validationMessages.minLengthValidation", {
        field,
        validLength: minLength,
      })
    );
};
export const newMessageValidationSchema = (t: any) =>
  object().shape({
    title: nameValidation(t("fields.title"), t, 3, 25),
    body: nameValidation(t("fields.message"), t, 3, 1000),
  });
