import React, { FC, ReactNode } from "react";
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  SubmitHandler,
  UseFormReturn,
} from "react-hook-form";
import { Box, Button } from "@chakra-ui/react";
import { FormItemObject } from "src/types/form";
import FormRow from "src/components/app/FormRow";
import { FormItem as FormItemControl } from "./FomItem";

export interface FormGeneratorProps<
  TFieldValues extends FieldValues = Record<string, any>
> {
  formItems: FormItemObject;
  onSubmit: SubmitHandler<FieldValues | any>;
  useFormReturn: UseFormReturn<TFieldValues>;
  submitLoading?: boolean;
  before?: ReactNode;
  after?: ReactNode;
  submitText?: string;
  isCancel?: boolean;
  cancelText?: string;
  onCancel?: () => void;
  showBoxBtn?: boolean;
}

const FormGenerator: FC<FormGeneratorProps> = ({
  formItems,
  onSubmit,
  useFormReturn,
  submitLoading,
  before: Before,
  after: After,
  submitText,
  isCancel = false,
  cancelText,
  onCancel,
  showBoxBtn = true,
  ...remainProps
}) => {
  return (
    <form
      onSubmit={useFormReturn.handleSubmit(onSubmit)}
      noValidate
      {...remainProps}
    >
      {Before}
      {Object.values(formItems).map((formItem) => {
        return (
          <FormRow
            key={formItem.name}
            bidirectional={formItem.bidirectional}
            leftContent={
              <FormItemControl
                useFormReturn={useFormReturn}
                formItem={formItem}
              />
            }
            rightContent={
              formItem?.bidirectional && formItem?.rightContent ? (
                <FormItemControl
                  useFormReturn={useFormReturn}
                  formItem={formItem?.rightContent}
                />
              ) : (
                ""
              )
            }
          />
        );
      })}
      {After}
      {showBoxBtn && (
        <Box textAlign="right">
          {isCancel && (
            <Button onClick={onCancel} size="lg" colorScheme="orange" mr="4">
              {cancelText || "Cancel"}
            </Button>
          )}
          <Button
            colorScheme="primary"
            size="lg"
            type="submit"
            isLoading={submitLoading}
          >
            {submitText || "Submit"}
          </Button>
        </Box>
      )}
    </form>
  );
};

export default FormGenerator;
