import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  Textarea,
  ModalContent,
  ModalOverlay,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
} from "@chakra-ui/react";
import { mdiClose, mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import "./addNewMessageModal.css";

import { INewMessageData } from "src/api/types/messages";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { newMessageValidationSchema } from "src/validation-schemas/message";
import { useTranslation } from "react-i18next";
import { messagesService } from "src/api/services/messages";
import { useAbortController } from "src/hooks/useAbortController";
import { toast } from "src/utils/toast";
import ZonesSelectContainer from "./ZonesSelect/Container";
import RidersSelectContainer from "./RidersSelect/Container";

const STEPS = {
  TEMPLATE: 1,
  MONTH: 2,
  MESSAGE: 3,
  RECIPIENTS: 4,
} as const;

type StepType = typeof STEPS[keyof typeof STEPS];

interface IAddNewMessageModalProps {
  data: { onCreateNewMessageSuccess: () => void };
  closeModal: (clearModals: boolean) => void;
}

const defaultBtnConfig = {
  content: "",
  action: () => {},
  isDisabled: false,
  loading: false,
};

type defaultBtnType = {
  content: string | React.ReactNode;
  action: () => void;
  isDisabled?: boolean;
  loading?: boolean;
};

const ButtonBox = ({
  lBtnConfig = defaultBtnConfig,
  rBtnConfig = defaultBtnConfig,
}: {
  lBtnConfig: defaultBtnType;
  rBtnConfig: defaultBtnType;
}) => {
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        margin={"30px 0 20px 0"}
      >
        <Button
          onClick={() => lBtnConfig.action()}
          size="lg"
          border={"1px solid lightGray"}
          bgColor={"white"}
          color={"black"}
          mr="4"
          isLoading={lBtnConfig.loading}
          isDisabled={lBtnConfig.isDisabled}
          isFullWidth={true}
        >
          {lBtnConfig.content}
        </Button>
        <Button
          size="lg"
          type="submit"
          onClick={() => rBtnConfig.action()}
          isFullWidth={true}
          isLoading={rBtnConfig.loading}
          bgColor={"#FEE4BA"}
          color={"black"}
          border={"1px solid #FEE4BA"}
          isDisabled={rBtnConfig.isDisabled}
        >
          {rBtnConfig.content}
        </Button>
      </Box>
    </>
  );
};

interface Template {
  id: string;
  title: string;
  body: string;
}

const TemplateCard = ({
  template,
  isSelected,
  onClick,
}: {
  template: Template;
  isSelected: boolean;
  onClick: () => void;
}) => (
  <Box
    p={4}
    border="1px solid"
    borderColor={isSelected ? "orange.300" : "gray.200"}
    borderRadius="lg"
    cursor="pointer"
    onClick={onClick}
    mb={4}
    bg={isSelected ? "#FFF9F0" : "white"}
    _hover={{ borderColor: "orange.300" }}
    transition="all 0.2s"
  >
    <Text fontWeight="500" mb={2}>
      {template.title}
    </Text>
    <Text color="gray.600" fontSize="sm">
      {template.body}
    </Text>
  </Box>
);

type StepContentProps = {
  TEMPLATE: JSX.Element;
  MONTH: JSX.Element;
  MESSAGE: JSX.Element;
  RECIPIENTS: JSX.Element;
};

const AddNewMessageModal: React.FC<IAddNewMessageModalProps> = ({
  data: dataFromProps,
  closeModal,
}) => {
  const { getAbortSignal } = useAbortController();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<StepType>(STEPS.TEMPLATE);
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [months, setMonths] = useState<{ id: number; date: string }[] | any>(
    []
  );
  const [loadingMonths, setLoadingMonths] = useState(false);
  const [selectedZones, setSelectedZones] = useState<string[]>([]);
  const [zonesAllSelected, setZonesAllSelected] = useState(false);
  const [ridersAllSelected, setRidersAllSelected] = useState(false);
  const [selectedRiders, setSelectedRiders] = useState<string[]>([]);

  const {
    register,
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm<INewMessageData>({
    resolver: yupResolver(newMessageValidationSchema(t)),
    mode: "onChange",
    defaultValues: {
      title: "",
      body: "",
    },
  });

  const [templates, setTemplates] = useState<Template[] | any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isRawMessageSelected, setRawMessageSelected] = useState(false);
  const IS_TEMPLATE_MESSAGE = !!selectedTemplate;

  useEffect(() => {
    if (selectedTemplate) {
      const template = templates.find((t: any) => t.id === selectedTemplate);
      if (template) {
        reset({
          title: template.title,
          body: template.body,
        });
      }
    } else {
      reset({
        title: "",
        body: "",
      });
    }
  }, [selectedTemplate, templates, reset]);

  const fetchTemplates = async (page?: number) => {
    try {
      setLoadingTemplates(true);
      const response = await messagesService.getTemplates();
      const newTemplates = response.data;
      setTemplates(newTemplates);

      // if (page === 1) {
      //   setTemplates(newTemplates);
      // } else {
      //   setTemplates((prev) => [...prev, ...newTemplates]);
      // }

      // @ts-ignore
      setHasMore(newTemplates.length === 10);
      setCurrentPage(1);
    } catch (error) {
      console.error("Error fetching templates:", error);
    } finally {
      setLoadingTemplates(false);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const loadMore = () => {
    if (!loadingTemplates && hasMore) {
      fetchTemplates(currentPage + 1);
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (
      !loadingTemplates &&
      hasMore &&
      scrollHeight - scrollTop <= clientHeight * 1.5
    ) {
      loadMore();
    }
  };

  useEffect(() => {
    const fetchMonths = async () => {
      try {
        setLoadingMonths(true);
        const response = await messagesService.getMonths();
        setMonths(response.data);
      } catch (error) {
        console.error("Error fetching months:", error);
      } finally {
        setLoadingMonths(false);
      }
    };

    if (currentStep === STEPS.MONTH) {
      fetchMonths();
    }
  }, [currentStep]);

  const onSubmit = async (data: INewMessageData) => {
    setLoading(true);
    const values = getValues();
    try {
      const payload = {
        Title: values?.title,
        Body: values?.body,
        // @ts-ignore
        zoneIds: zonesAllSelected ? [] : selectedZones.map((item) => item.id),
        // @ts-ignore
        riderIds: ridersAllSelected
          ? []
          : // @ts-ignore
            selectedRiders.map((item) => item.id),
        IsTemplateMessage: IS_TEMPLATE_MESSAGE,
        ...(IS_TEMPLATE_MESSAGE
          ? {
              TemplateMessageId: selectedTemplate,
              TimePeriodId: selectedMonth,
            }
          : {}),
      };
      await messagesService.create(
        payload,
        getAbortSignal("createMessage").signal
      );
      toast.success(t("messages.messageSentSuccessfully"));
      dataFromProps.onCreateNewMessageSuccess();
      closeModal(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    switch (currentStep) {
      case STEPS.TEMPLATE:
        if (isRawMessageSelected) {
          setCurrentStep(STEPS.MESSAGE);
        } else {
          setCurrentStep(STEPS.MONTH);
        }
        break;
      case STEPS.MONTH:
        setCurrentStep(STEPS.MESSAGE);
        break;
      case STEPS.MESSAGE:
        setCurrentStep(STEPS.RECIPIENTS);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    const previousSteps: Record<StepType, StepType> = {
      [STEPS.TEMPLATE]: STEPS.TEMPLATE,
      [STEPS.MONTH]: STEPS.TEMPLATE,
      [STEPS.MESSAGE]: STEPS.MONTH,
      [STEPS.RECIPIENTS]: STEPS.MESSAGE,
    };
    setCurrentStep(previousSteps[currentStep]);
  };

  const getStepTitle = () => {
    switch (currentStep) {
      case STEPS.TEMPLATE:
        return "Choose Template";
      case STEPS.MONTH:
        return "Select Month";
      case STEPS.MESSAGE:
        return "New Notification";
      case STEPS.RECIPIENTS:
        return "Select Zones or Riders";
      default:
        return "";
    }
  };

  const renderStepContent = (): StepContentProps[keyof StepContentProps] => {
    switch (currentStep) {
      case STEPS.TEMPLATE:
        return (
          <Box>
            <Box
              p={4}
              border="1px solid"
              borderRadius="lg"
              cursor="pointer"
              mb={4}
              onClick={() => {
                setSelectedTemplate("");
                setRawMessageSelected(true);
                // handleNext();
              }}
              borderColor={isRawMessageSelected ? "orange.300" : "gray.200"}
              bg={isRawMessageSelected ? "#FFF9F0" : "white"}
              _hover={{ borderColor: "orange.300" }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Text fontWeight="500">New message</Text>
                  <Text color="gray.600" fontSize="sm">
                    You can write new notification
                  </Text>
                </Box>
                <Icon path={mdiPencil} size={1} />
              </Box>
            </Box>

            <Box maxHeight="300px" overflowY="auto" onScroll={handleScroll}>
              {!loadingTemplates &&
                templates.map((template: Template, index: number) => (
                  <TemplateCard
                    key={index}
                    template={template}
                    isSelected={selectedTemplate === template.id}
                    onClick={() => {
                      setSelectedTemplate(template.id);
                      if (isRawMessageSelected) {
                        setRawMessageSelected(false);
                      }
                    }}
                  />
                ))}
              {loadingTemplates && (
                <Box p={4} textAlign="center">
                  Loading more templates...
                </Box>
              )}
            </Box>

            <ButtonBox
              lBtnConfig={{
                content: "Cancel",
                action: () => closeModal(true),
              }}
              rBtnConfig={{
                content: "Next",
                action: handleNext,
                isDisabled: !(isRawMessageSelected || selectedTemplate),
              }}
            />
          </Box>
        );

      case STEPS.MONTH:
        return (
          <Box>
            <Text mb={4}>Select the month to see extra fills amount</Text>
            <Box mb={6}>
              <Select
                placeholder="Select month"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                size="lg"
                bg="white"
              >
                {months.map((month: any) => (
                  <option key={month.id} value={month.id}>
                    {month.date}
                  </option>
                ))}
              </Select>
            </Box>
            <ButtonBox
              lBtnConfig={{
                content: "Back",
                action: handleBack,
              }}
              rBtnConfig={{
                content: "Next",
                action: handleNext,
                isDisabled: !selectedMonth,
              }}
            />
          </Box>
        );

      case STEPS.MESSAGE:
        const selectedTemplateData = templates.find(
          (t: any) => t.id === selectedTemplate
        );
        return (
          <form onSubmit={handleSubmit(handleNext)} noValidate>
            <Box textAlign="right" margin={"20px 0 20px 0"}>
              <FormControl isInvalid={!!errors.title} isRequired>
                <FormLabel htmlFor="title">{t("fields.title")}</FormLabel>
                <Input
                  id="title"
                  type="text"
                  size="lg"
                  {...register("title")}
                  isReadOnly={!!selectedTemplateData}
                />
                <FormErrorMessage>
                  {!!errors.title && errors.title.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box textAlign="right">
              <FormControl isInvalid={!!errors.body} isRequired>
                <FormLabel htmlFor="body">{t("fields.message")}</FormLabel>
                <Textarea
                  id="body"
                  rows={10}
                  {...register("body")}
                  isReadOnly={!!selectedTemplateData}
                />
                <FormErrorMessage>
                  {!!errors.body && errors.body.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <ButtonBox
              lBtnConfig={{
                content: "Back",
                action: () => {
                  if (!IS_TEMPLATE_MESSAGE) {
                    setCurrentStep(STEPS.TEMPLATE);
                    return;
                  }
                  handleBack();
                },
              }}
              rBtnConfig={{
                content: "Next",
                action: handleNext,
                isDisabled: !isValid,
              }}
            />
          </form>
        );

      case STEPS.RECIPIENTS:
        return (
          <Box p="0">
            <Tabs colorScheme={"orange"}>
              <TabList mb="1em">
                <Tab>Zones</Tab>
                <Tab>Riders</Tab>
              </TabList>
              <TabPanels>
                <TabPanel p="0">
                  <ZonesSelectContainer
                    allSelectedCount={selectedZones.length}
                    onZoneSelected={(zones) => setSelectedZones(zones)}
                    onAllSelected={(value) => setZonesAllSelected(value)}
                  />
                </TabPanel>
                <TabPanel p="0">
                  <RidersSelectContainer
                    onRiderSelected={(riders) => {
                      setSelectedRiders(riders);
                    }}
                    onAllSelected={(value) => setRidersAllSelected(value)}
                    allSelectedCount={selectedRiders.length}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
            <ButtonBox
              lBtnConfig={{
                content: "Back",
                action: handleBack,
              }}
              rBtnConfig={{
                content: "Send",
                action: () => onSubmit({ title: "", body: "" }),
                isDisabled: loading,
              }}
            />
          </Box>
        );
    }
  };

  return (
    <Modal isOpen={true} onClose={() => closeModal(true)} size="lg">
      <ModalOverlay />
      <ModalContent p="8" borderRadius={"3xl"}>
        <Box
          mb="4"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text as="h3" fontWeight="500" fontSize="1rem">
            {getStepTitle()}
          </Text>
          <Text
            as="span"
            color="gray"
            cursor="pointer"
            onClick={() => closeModal(true)}
            className="closeModal"
          >
            <Icon path={mdiClose} size="22px" />
          </Text>
        </Box>
        {renderStepContent()}
      </ModalContent>
    </Modal>
  );
};

export default AddNewMessageModal;
