import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Box, Text, IconButton, Button } from "@chakra-ui/react";
import Table from "src/components/app/Table";
import Icon from "@mdi/react";
import { mdiEye, mdiPencil, mdiPlus, mdiTrayArrowDown } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import { IZoneListItem, ZoneSorts } from "src/api/types/zones";
import { panelZonesService } from "src/api/services/zones";
import { Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { exportFile } from "src/utils/export-file";
import { ApiConfig } from "src/api/config";
import { API_ROUTES } from "src/constants/api-routes";
import dateHelper from "src/utils/date-helper";
import { Pagination } from "src/components/app/Pagination";
import { DEFAULT_COUNT } from "src/constants/api-default-values";
import { SearchInput } from "src/components/app/SearchInput";
import { useAsync } from "src/hooks/useAsync";
import { IFetchDataResponse, IFetchFilters } from "src/types/general";
import { useAbortController } from "src/hooks/useAbortController";
import useQueryParam from "src/hooks/useSearchQuery";
import { modalActions } from "src/global-context/modals";
import { MODAL_TYPES } from "src/types/modals";
import { ICustomOption } from "src/types/table";
import { useTable } from "src/hooks/useTable";

const Zones: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.zones"));

  const exportButton = useRef<any>();
  const { getAbortSignal } = useAbortController();
  const { queryParams, updateQueryParams, initialized } = useQueryParam({
    initialFields: {
      size: 10,
      offset: 0,
      query: "",
    },
  });

  const columnsTable = useMemo(
    () => [
      {
        title: t("fields.nameEn"),
        dataIndex: "nameEn",
        isSortable: true,
        isHidden: false,
      },
      {
        title: "Arabic Name",
        dataIndex: "nameAr",
        isSortable: true,
        isHidden: false,
      },
      {
        title: "Number Of RidersSelect",
        dataIndex: "riderCount",
        isSortable: true,
        isHidden: false,
      },
    ],
    []
  );

  type TCustomOptions = {
    group: ICustomOption<{ nameEn: string }>;
    validRadius: ICustomOption<IZoneListItem>;
    riderCount: ICustomOption<IZoneListItem>;
    actions: ICustomOption<IZoneListItem>;
    manageRidersAction: ICustomOption<IZoneListItem>;
  };

  const onManageRider = useCallback(
    (item: IZoneListItem) => {
      const { offset, size } = queryParams;
      modalActions.addModal(MODAL_TYPES.MANAGE_ZONE_RIDERS_MODAL, {
        zone: item,
        onClose: () => fetchZones({ offset, size }),
      });
    },
    [queryParams]
  );

  const customOption: TCustomOptions = useMemo(() => {
    return {
      group: {
        title: "Groups",
        dataIndex: "group",
        render: (value) => <Box>{value?.nameEn}</Box>,
      },
      validRadius: {
        title: "Radius",
        dataIndex: "validRadius",
        render: (value) => <Box>{value ? value : 0}</Box>,
      },
      riderCount: {
        title: "Number Of RidersSelect",
        dataIndex: "riderCount",
        render: (value) => <Box>{value ? value : 0}</Box>,
      },
      actions: {
        title: "",
        dataIndex: "actions",
        render: (_, item) => (
          <Box display="flex">
            <IconButton
              aria-label="Test"
              icon={<Icon path={mdiPencil} size="28px" />}
              color="gray.100"
              bg="blue.300"
              as={Link}
              to={
                ROUTE_CONSTANTS.DASHBOARD.ZONES.UPDATE.BY_DATA(item.id).ABSOLUTE
              }
            />
            <IconButton
              aria-label="Test"
              icon={<Icon path={mdiEye} size="28px" />}
              color="gray.100"
              bg="blue.300"
              as={Link}
              ml="2"
              to={
                ROUTE_CONSTANTS.DASHBOARD.ZONES.DETAILS.BY_DATA(item.id)
                  .ABSOLUTE
              }
            />
          </Box>
        ),
      },
      manageRidersAction: {
        title: "",
        dataIndex: "manageRidersAction",
        render: (_, item) => (
          <Button
            type="button"
            size="sm"
            fontWeight="normal"
            onClick={() => onManageRider(item)}
          >
            Manage Riders
          </Button>
        ),
      },
    };
  }, [onManageRider]);

  const {
    run: fetchZones,
    isLoading,
    data: zonesData,
  } = useAsync<IFetchDataResponse<IZoneListItem>, IFetchFilters>((options) => {
    const { offset, size, query = queryParams.query } = options;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await panelZonesService.getAll(
          offset,
          size,
          ZoneSorts.ByCreatedAtDesc,
          getAbortSignal("fetchZones").signal,
          query
        );
        resolve(response.data.data as IFetchDataResponse<IZoneListItem>);
      } catch (e) {
        reject([]);
      }
    });
  });

  const { columnsArray } = useTable<IZoneListItem>({
    columnsTable: columnsTable,
    loading: isLoading,
    customSort: [
      "nameEn",
      "nameAr",
      "validRadius",
      "group",
      "riderCount",
      "manageRidersAction",
      "actions",
    ],
    suffixColNames: ["actions", "group", "manageRidersAction"],
    customOption: customOption,
  });

  useEffect(() => {
    if (initialized) {
      const { offset, size, query } = queryParams;
      fetchZones({ offset, size, query });
    }
  }, [initialized]);

  const search = (searched: string) => {
    updateQueryParams({
      query: searched.toLocaleLowerCase().trim(),
      offset: 0,
    });
    fetchZones({
      offset: 0,
      size: queryParams.size,
      query: searched.toLocaleLowerCase().trim(),
    });
  };

  const exportCSV = () => {
    exportFile(
      ApiConfig.baseURL + API_ROUTES.ZONES.EXPORT,
      {
        count: "" + DEFAULT_COUNT,
        keyword: "" + queryParams.query,
      },
      exportButton,
      `Zones-${dateHelper.getToday()}`
    );
  };

  const handlePassPaginateValues = (offset: number, size: number) => {
    updateQueryParams({ offset: offset, size: size });
    fetchZones({ offset, size });
  };
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Text as="h3" fontSize="3xl">
          {t("titles.zones")}
        </Text>
        <IconButton
          aria-label="New Zone"
          as={Link}
          to={ROUTE_CONSTANTS.DASHBOARD.ZONES.CREATE.ABSOLUTE}
          ml="4"
          icon={<Icon path={mdiPlus} size="28px" />}
          colorScheme="primary"
          variant="outline"
          size="sm"
        />
      </Box>
      <Box
        mb="4"
        mt="4"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SearchInput
          searchPlaceholder={t("actions.search")}
          value={queryParams.query}
          onSearchInput={search}
        />
        <Button
          bg="#F39905"
          color="#333"
          ml="2"
          pr="20px"
          pl="20px"
          ref={exportButton}
          onClick={() => exportCSV()}
        >
          <Icon path={mdiTrayArrowDown} size="25px" color="#333" />
          &nbsp;Export
        </Button>
      </Box>
      <Table
        loading={isLoading}
        dataSource={zonesData?.items}
        columns={columnsArray || []}
      />
      <Pagination
        entityTitle="Zone"
        loading={isLoading}
        pageTotalCount={zonesData?.totalCount || 0}
        perPageItems={queryParams.size || 10}
        passPaginateValues={handlePassPaginateValues}
      />
    </Box>
  );
};
export default Zones;
