import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Text, FormLabel, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import { useNavigate, useParams } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { INewZoneData, IZoneListItem } from "src/api/types/zones";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ContentView, {
  ContentViewBody,
  ContentViewHeader,
} from "src/components/app/ContentView";
import { panelZonesService } from "src/api/services/zones";
import { toast } from "src/utils/toast";
import { DEFAULT_CENTER } from "src/constants/map-data";
import { BrowserBack } from "src/components/app/BrowserBack";
import { getCreateRiderFormItems } from "./form-item";
import { useAbortController } from "src/hooks/useAbortController";
import { useFormItems } from "src/hooks/useFormItems";
import FormGenerator from "src/components/FormGenerator";
import { useAsync } from "src/hooks/useAsync";
import { modalActions } from "src/global-context/modals";
import { MODAL_TYPES } from "src/types/modals";
import { newZoneValidationSchema } from "./validation-schema";
import { PolygonMap } from "../../../../components/app/Map/PolygonMap";

const CreateZone: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const params = useParams<{ id: string }>();
  const { getAbortSignal } = useAbortController();
  const [location, setLocation] = useState<any>(DEFAULT_CENTER);
  const [coordinates, setCoordinates] = useState<any>([]);
  const [initialLocation, setInitialLocation] = useState(DEFAULT_CENTER);
  const [initialCenter, setInitialCenter] = useState(DEFAULT_CENTER);
  const [initialCoordinates, setInitialCoordinates] = useState<any>([]);
  const formRef = useRef<HTMLDivElement>(null);
  useTitle(t(editable ? "pageTitles.editZone" : "pageTitles.newZone"));

  const createRiderFormItems = useMemo(
    () =>
      getCreateRiderFormItems({
        t,
        getAbortSignal,
      }),
    [t, getAbortSignal, editable]
  );
  const { formItems } = useFormItems(createRiderFormItems);

  const useFormReturn = useForm<INewZoneData>({
    resolver: yupResolver(newZoneValidationSchema(t)),
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      nameEn: "",
      nameAr: "",
      descriptionEn: "",
      descriptionAr: "",
      pagingRadius: 0,
      groupId: "",
      addressEn: "",
      addressAr: "",
      expectedOrderNumberForWeekends: 0,
      expectedOrderNumberForRegularDays: 0,
    },
  });

  const { trigger, getValues } = useFormReturn;

  const { run: getZoneDetails, data: zonesData } = useAsync<IZoneListItem, any>(
    () => {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await panelZonesService.get(
            params.id!,
            getAbortSignal("fetchZoneDetails").signal
          );
          const { ...rest } = result.data.data;
          useFormReturn.reset(rest);
          setInitialLocation({
            lat: rest.location.latitude,
            lng: rest.location.longitude,
          });
          setInitialCenter({
            lat: rest.location.latitude,
            lng: rest.location.longitude,
          });
          setInitialCoordinates(rest.coordinates);
          resolve(rest as IZoneListItem);
        } catch (e) {
          reject([]);
        }
      });
    }
  );

  const center = useMemo(() => {
    return initialCenter;
  }, [initialCenter]);

  useEffect(() => {
    if (params.id) {
      setEditable(true);
      getZoneDetails({});
    }
  }, [params]);

  const onCustomLocationEntered = (lat: number, lng: number) => {
    setInitialLocation({ lat, lng });
    setInitialCenter({ lat, lng });
  };

  const validateForm = async () => {
    const isFormValid = await trigger();
    const isMapDataValid = location && coordinates && coordinates.length > 0;
    if (!isMapDataValid) {
      toast.error(
        "Please select the map data and fill the required information"
      );
      return false;
    }
    if (!isFormValid) {
      // Try scrolling using the ref
      formRef.current?.scrollIntoView({ behavior: "smooth" });
      // Or alternatively
      if (formRef.current) {
        formRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
    return isFormValid;
  };

  const onSubmit = async (data: INewZoneData) => {
    setLoading(true);
    const allData = {
      ...data,
      location: {
        latitude: location.lat,
        longitude: location.lng,
      },
      coordinates,
    };
    try {
      if (editable) {
        await panelZonesService.update(
          params.id!,
          allData,
          getAbortSignal("saveZoneDetails").signal
        );
      } else {
        await panelZonesService.create(
          allData,
          getAbortSignal("saveZoneDetails").signal
        );
      }
      toast.success(
        t(
          editable
            ? "messages.zoneUpdatedSuccessfully"
            : "messages.zoneCreatedSuccessfully"
        )
      );
      editable
        ? navigate(-1)
        : navigate(ROUTE_CONSTANTS.DASHBOARD.ZONES.ROOT.ABSOLUTE);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const manualSubmit = async () => {
    const isValid = await validateForm();
    if (!isValid) {
      return;
    }
    const formData = getValues();
    await onSubmit(formData);
  };

  return (
    <>
      <ContentView>
        <ContentViewHeader>
          <Box display="flex" alignItems="center" ref={formRef}>
            <Text as="h3" fontSize="3xl">
              {t(editable ? "titles.editZone" : "titles.newZone")}
            </Text>
            <BrowserBack />
          </Box>
        </ContentViewHeader>
        <ContentViewBody>
          <FormGenerator
            before={<></>}
            isCancel={true}
            onCancel={() =>
              editable
                ? navigate(-1)
                : navigate(ROUTE_CONSTANTS.DASHBOARD.ZONES.ROOT.ABSOLUTE)
            }
            submitLoading={loading}
            formItems={formItems}
            onSubmit={onSubmit}
            useFormReturn={useFormReturn as any}
            showBoxBtn={false}
          />
          <Box>
            <Box display="flex" alignItems="center" mb="3">
              <FormLabel m="0">{t("fields.location")}</FormLabel>
              <Button
                size="sm"
                ml="2"
                type="button"
                onClick={() =>
                  modalActions.addModal(MODAL_TYPES.EDIT_ZONE_LOCATION_MODAL, {
                    onConfirm: onCustomLocationEntered,
                  })
                }
                colorScheme="secondary"
              >
                Enter Manually
              </Button>
            </Box>
            <Box mb="3">
              <span>Selected Coordinates: </span>
              <Text as="span" fontWeight="bold">{`${location?.lat || "_"}, ${
                location?.lng || "_"
              }`}</Text>
            </Box>
            <PolygonMap
              center={center}
              zoom={16}
              onMarkerChanged={(marker) => setLocation(marker)}
              onPolygonChanged={(coordinates) => setCoordinates(coordinates)}
              defaultMarker={initialLocation}
              defaultPolyline={initialCoordinates}
            />
            <Box textAlign="right" marginTop={4}>
              <Button
                onClick={() =>
                  editable
                    ? navigate(-1)
                    : navigate(ROUTE_CONSTANTS.DASHBOARD.ZONES.ROOT.ABSOLUTE)
                }
                size="lg"
                colorScheme="orange"
                mr="4"
              >
                {t("actions.cancel")}
              </Button>
              <Button
                onClick={() => {
                  manualSubmit();
                }}
                colorScheme="primary"
                size="lg"
                isLoading={loading}
              >
                {t("actions.save")}
              </Button>
            </Box>
          </Box>
        </ContentViewBody>
      </ContentView>
    </>
  );
};

export default CreateZone;
