import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Modal,
  Text,
  ModalContent,
  ModalOverlay,
  Box,
  Center,
} from "@chakra-ui/react";
import { NotFoundResult } from "src/components/app/NotFoundResult";
import SPSpinner from "src/components/app/SPSpinner";
import { DEFAULT_OFFSET } from "src/constants/api-default-values";
import RiderList from "./Table";
import { panelNotificationService } from "../../../../api/services/notifications";

interface IRidersModalProps {
  isOpen: boolean;
  handleClose: () => void;
  getDataFrom?: () => {};
  item: any;
}

export interface IRiders {
  distance: number;
  employeeId: string;
  firstName: string;
  id: string;
  lastName: string;
  contactNo: string;
  location: {
    latitude: number;
    longitude: number;
  };
  riderId: string;
  status: string;
  isRejected: boolean;
  zone: string;
}

const RiderListModal: React.FC<IRidersModalProps> = ({
  isOpen,
  handleClose,
  item,
}) => {
  const [riders, setRiders] = useState<IRiders[]>([]);
  const [showNoResultFound, setShowNoResultFound] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const DEFAULT_LIST_COUNT = 15;
  const getRiders = async () => {
    setLoading(true);
    try {
      const result = await panelNotificationService.getReceiver(
        offset,
        DEFAULT_LIST_COUNT,
        item.id
      );
      const fetchedRiders = result.data.data.items;

      setRiders((prevRiders) => [...prevRiders, ...fetchedRiders]);

      setHasMore(fetchedRiders.length >= DEFAULT_LIST_COUNT);

      setOffset((prevOffset) => prevOffset + DEFAULT_LIST_COUNT);

      setShowNoResultFound(fetchedRiders.length === 0 && riders.length === 0);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setRiders([]); // Reset the riders on open
      setOffset(DEFAULT_OFFSET); // Reset offset
      setHasMore(true); // Reset hasMore
      getRiders();
    }
  }, [isOpen]);

  const observer = useRef<IntersectionObserver>();
  const lastRiderElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getRiders();
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const renderEligibleRidersTable = () => {
    if (loading && riders.length === 0) {
      return (
        <Center mt={2} mb={2} minHeight={200}>
          <SPSpinner size="lg" />
        </Center>
      );
    } else {
      if (!showNoResultFound) {
        return <RiderList riders={riders} lastRiderRef={lastRiderElementRef} />;
      }
      return <NotFoundResult iconMaxWidth={140} />;
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <Box
            mb={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgColor="rgba(231, 231, 231, 0.16)"
            p={3}
            borderBottom="1px solid #E7E7E7"
            borderRadius="8px 8px 0 0"
          >
            <Text as="p" fontWeight="500" fontSize="1.3rem">
              Riders list
            </Text>
          </Box>
          {renderEligibleRidersTable()}
        </ModalContent>
      </Modal>
    </>
  );
};

export default RiderListModal;
