import { IPaginatedResult } from "./axios-response";
import { IGroupListItem } from "./groups";
import { ILatLng } from "../../types/map";

export interface IZoneListItem {
  id: string;
  nameAr: string;
  nameEn: string;
  descriptionAr: string;
  descriptionEn: string;
  validRadius: number;
  addressEn: string;
  addressAr: string;
  location: ILocation;
  coordinates?: ILatLng[];
  group: IGroupListItem;
  riderCount: number;
  pagingRadius: number;
  username: string;
  hasOtp: boolean;
  expectedOrderNumberForRegularDays: number;
  expectedOrderNumberForWeekends: number;
}

export interface IZonesListPayload extends IPaginatedResult<IZoneListItem> {}

export interface INewZoneData {
  nameAr: string;
  nameEn: string;
  descriptionAr: string;
  descriptionEn: string;
  groupId: string;
  location: ILocation;
  addressEn: string;
  addressAr: string;
  pagingRadius: number;
  expectedOrderNumberForRegularDays: number;
  expectedOrderNumberForWeekends: number;
}

export interface ILocation {
  latitude: number;
  longitude: number;
}

export interface IMiniZoneData {
  id: string;
  groupId: string;
  nameAr: string;
  nameEn: string;
  location: ILocation;
  validRadius: number;
}

export enum ZoneSorts {
  None = "None",
  ByNameEnAsc = "ByNameEnAsc",
  ByCreatedAtDesc = "ByCreatedAtDesc",
}

export interface IZoneAccountData {
  username: string;
  rawPassword: string;
}
