import { FORM_ITEM_TYPE } from "../../../../types/form";
import { TFunction } from "i18next";
import { panelGroupsService } from "src/api/services/groups";
import {
  DEFAULT_COUNT,
  DEFAULT_OFFSET,
} from "src/constants/api-default-values";

export const getCreateRiderFormItems = ({
  t,
  getAbortSignal,
}: {
  t: TFunction;
  getAbortSignal: (requestName: string) => AbortController;
}) => {
  return {
    nameEn: {
      label: t("fields.nameEn"),
      name: "nameEn",
      type: FORM_ITEM_TYPE.Text,
      required: true,
      bidirectional: true,
      rightContent: {
        label: t("fields.nameAr"),
        name: "nameAr",
        type: FORM_ITEM_TYPE.Text,
        required: true,
        bidirectional: true,
      },
    },
    descriptionEn: {
      label: t("fields.descriptionEn"),
      name: "descriptionEn",
      type: FORM_ITEM_TYPE.Text,
      required: true,
      bidirectional: true,
      rightContent: {
        label: t("fields.descriptionAr"),
        name: "descriptionAr",
        type: FORM_ITEM_TYPE.Text,
        required: true,
        bidirectional: true,
      },
    },
    groupId: {
      label: t("fields.groupId"),
      name: "groupId",
      type: FORM_ITEM_TYPE.Select,
      hasNoneOption: true,
      fetcher: () =>
        panelGroupsService
          .getAll(
            DEFAULT_OFFSET,
            DEFAULT_COUNT,
            getAbortSignal("fetchZoneGroups").signal
          )
          .then((res) =>
            res.data.data.items.map((item) => ({
              title: `${item.nameEn} --- ${item.nameAr}`,
              value: item.id,
            }))
          ),
    },
    pagingRadius: {
      label: `${t("fields.pagingRadius")} (m)`,
      name: "pagingRadius",
      type: FORM_ITEM_TYPE.Number,
    },
    expectedOrderNumberForRegularDays: {
      label: t("fields.expectedOrder"),
      name: "expectedOrderNumberForRegularDays",
      type: FORM_ITEM_TYPE.Number,
    },
    expectedOrderNumberForWeekends: {
      label: t("fields.expectedWeekendOrder"),
      name: "expectedOrderNumberForWeekends",
      type: FORM_ITEM_TYPE.Number,
    },
    addressEn: {
      label: t("fields.addressEn"),
      name: "addressEn",
      type: FORM_ITEM_TYPE.Text,
      required: true,
      bidirectional: true,
      rightContent: {
        label: t("fields.addressAr"),
        name: "addressAr",
        type: FORM_ITEM_TYPE.Text,
        required: true,
        bidirectional: true,
      },
    },
  };
};
