import "react-datepicker/dist/react-datepicker.css";

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import HorizontalLoading from "../../../../../components/app/HorizontalLoading";
import { NotFoundResult } from "../../../../../components/app/NotFoundResult";
import {
  IOngoingOrders,
  IOrderFilters,
  IOrderType,
} from "../../../../../api/types/orders";
import { ordersService } from "../../../../../api/services/orders";
import dateHelper from "../../../../../utils/date-helper";
import OrderHistoryItem from "../OrderHistoryItem";
import { Pagination } from "../../../../../components/app/Pagination";
import { useTitle } from "../../../../../hooks/useTitle";
import {
  Box,
  Button,
  Select,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import AcceptedRiders from "../AcceptedRiders";
import EligibleRiders from "../EligibleRiders";
import { FilterDropDown } from "src/components/app/FilterDropdown/FilterDropDown";
import {
  DEFAULT_COUNT,
  DEFAULT_OFFSET,
} from "src/constants/api-default-values";
import { panelGroupsService } from "src/api/services/groups";
import { Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import Icon from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import { useForm } from "react-hook-form";
import { ControlledSelectBox } from "../../../../../components/app/SelectBox";
import { IRiderListItem } from "../../../../../api/types/riders";
import { toArray, unique } from "../../../../../utils/iterate";
import DatePicker from "react-datepicker";
import { useAsync } from "../../../../../hooks/useAsync";
import { IDropDownItem } from "../../../../../types/dropdownitem";
import useQueryParam from "../../../../../hooks/useSearchQuery";
import { useRidersFilter } from "src/hooks/useRidersFilter";

const OrderHistoryList: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.pagingHistory"));
  const { control, getValues, setValue, watch } = useForm();

  const registeredDateRecently = useRef<boolean>(false);
  const ridersOffset = useRef<number>(0);

  const {
    queryParams,
    updateQueryParams: setQueryParams,
    initialized,
  } = useQueryParam({
    initialFields: {
      size: 10,
    },
  });
  const [getAbortController] = useState<AbortController>(new AbortController());
  const [showNoResultFound, setShowNoResultFound] = useState<boolean>(false);
  const [isOpenAcceptedRiderModal, setIsOpenAcceptedRiderModal] =
    useState<boolean>(false);
  const [isOpenEligibleRiderModal, setIsOpenEligibleRiderModal] =
    useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<IOngoingOrders>(
    {} as IOngoingOrders
  );
  const [selectedOrderType, setSelectedOrderType] = useState<IOrderType>(
    IOrderType.None
  );

  const [pageTotalCount, setPageTotalCount] = useState<number>(0);
  const [filters, setFilters] = useState<IOrderFilters>({
    groupIds: [],
    riderIds: "",
    orderType: IOrderType.None,
    registeredDateRecently: false,
  });
  const [workDate, setWorkDate] = useState<string | undefined>();

  const {
    riders,
    isLoading: isLoadingRiders,
    handleScroll: handleScrollRidersFilter,
    handleSearch: handleSearchRiders,
  } = useRidersFilter(queryParams["riderIds"]);

  const fetchGroupsListAsync = useAsync<IDropDownItem[], any>(
    () => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await panelGroupsService.getAll(
            DEFAULT_OFFSET,
            DEFAULT_COUNT,
            getAbortController.signal
          );
          const groupsListMap = res.data.data.items.map((item) => ({
            id: item.id,
            text: item.nameEn,
            searchableFields: {
              nameEn: item.nameEn.trim(),
            },
          }));
          resolve(groupsListMap);
        } finally {
          resolve([]);
        }
      });
    },
    { immediate: true }
  );

  const fetchOrderHistoriesAsync = useAsync<
    IOngoingOrders[],
    { offset: number; size: number }
  >((options) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { end } = dateHelper.getFromStartUntilXHoursAgo(new Date(), 2);
        const toDate = dateHelper.formatDate(end, "YYYY-MM-DDTHH:mm:ssZ");
        const result = await ordersService.getOrderHistories(
          {
            offset: options.offset,
            count: options.size,
            toDate,
            ...handleQueryFilters(),
          },
          getAbortController.signal
        );

        setPageTotalCount(result.data.data.totalCount);
        result.data.data.items.length > 0
          ? setShowNoResultFound(false)
          : setShowNoResultFound(true);
        resolve(result.data.data.items);
      } catch (error) {
        console.log(error);
      } finally {
        resolve([]);
      }
    });
  });

  const handleSearch = () => {
    // updateSearchParameters({
    //   offset: 0,
    //   size: 10,
    //   riderIds: getValues("riderIds"),
    //   workDate,
    // });
    // setFilters({
    //   groupIds: selectedGroups.current,
    //   riderIds: getValues("riderIds"),
    //   orderType: selectedOrderType,
    //   registeredDateRecently: registeredDateRecently.current,
    // });
    fetchOrderHistoriesAsync.run({
      offset: +(queryParams["offset"] || 0),
      size: +(queryParams["size"] || 10),
    });
  };

  const handleQueryFilters = () => {
    const groups = toArray(queryParams["groupIds"]);
    const groupIds =
      groups.length > 0 ? `GroupIds=${groups.join("&GroupIds=")}` : "";
    const orderType = `orderType=${
      queryParams["pickupType"] || IOrderType.None
    }`;
    const riderIds = getValues("riderIds")
      ? `&RiderIds=${getValues("riderIds")}`
      : "";
    const registeredDateRecently = filters.registeredDateRecently
      ? `registeredDateRecently=${filters.registeredDateRecently}`
      : "";
    const workDateFormatted = queryParams["workDate"]
      ? dateHelper.formatDate(
          queryParams["workDate"] as any,
          "YYYY-MM-DDTHH:mm:ssZ"
        )
      : "";

    return {
      groupIds,
      orderType,
      registeredDateRecently,
      riderIds,
      workDate: workDateFormatted,
    };
  };

  const handlePassPaginateValues = (offset: number, size: number) => {
    if (initialized && !fetchOrderHistoriesAsync.isLoading) {
      setQueryParams({ size, offset });
      fetchOrderHistoriesAsync.run({ offset, size });
    }
  };

  const onChangeGroups = (data: string[]) => {
    console.log("CHANGE GROUPS");
    data = toArray(data);
    if (!data.length) {
      removeFieldFromQuery("groupIds");
    } else {
      setQueryParams({
        groupIds: unique(data as any),
        offset: 0,
      });
    }
  };

  const onChangeWorkDate = (date: Date) => {
    let updatedFromDate = dateHelper.formatDate(
      date.toString(),
      "YYYY-MM-DDTHH:mm:ssZ"
    );
    setQueryParams({
      workDate: dateHelper.formatDate(date.toString(), "YYYY-MM-DD"),
      offset: 0,
    });
    setWorkDate(updatedFromDate);
  };

  const sanitizeRiderName = (rider?: Partial<IRiderListItem>) => {
    if (!rider) return "";
    if (!rider.employeeId) return `All`;
    return `${rider.employeeId} - ${rider.firstName} ${rider.lastName}`;
  };

  const removeFieldFromQuery = (field: string) => {
    setValue(field, undefined);
    setFilters((prevFilter) => ({ ...prevFilter, [field]: "" }));
    setQueryParams({ [field]: undefined });
  };

  const onRemoveWorkdateClicked = () => {
    setWorkDate(undefined);
    removeFieldFromQuery("workDate");
  };

  const onChangePickupType = (pickupType: IOrderType) => {
    setSelectedOrderType(pickupType);
    setQueryParams({ pickupType, offset: 0 });
  };

  const onChangeOrderType = (value: any) => {
    registeredDateRecently.current = !!value;
    setQueryParams({ orderType: value, offset: 0 });
  };

  useEffect(() => {
    if (!initialized) return;
    if (queryParams["groupIds"]) {
      onChangeGroups(queryParams["groupIds"]);
    }
    if (queryParams.workDate) {
      onChangeWorkDate(queryParams.workDate);
    }
    if (queryParams.pickupType) {
      onChangePickupType(queryParams.pickupType);
    }
    if (queryParams.orderType) {
      onChangePickupType(queryParams.orderType);
    }
    if (queryParams.riderIds) {
      setValue("riderIds", queryParams.riderIds);
    }
    handleSearch();
  }, [initialized]);

  // useEffect(() => {
  // if (searchQuery.riderIds) {
  //   setValue("riderIds", searchQuery.riderIds);
  // }
  // }, [searchQuery.riderIds]);

  useEffect(() => {
    document
      .querySelector(".remove__workDate")
      ?.addEventListener("click", onRemoveWorkdateClicked);

    return () => {
      document
        .querySelector(".remove__workDate")
        ?.removeEventListener("click", onRemoveWorkdateClicked);
    };
  }, [workDate]);

  useEffect(() => {
    setQueryParams({
      riderIds: watch("riderIds"),
    });
    if (watch("riderIds") === undefined) removeFieldFromQuery("riderIds");
  }, [watch("riderIds")]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Text as="h3" fontSize="3xl">
          {t("titles.pagingHistory")}
        </Text>
        <Button
          as={Link}
          to={
            ROUTE_CONSTANTS.DASHBOARD.PAGING_ORDERS_HISTORY
              .CREATE_UPDATE_ORDER_BY_ADMIN.ABSOLUTE
          }
          variant="yellow_100"
          size="lg"
        >
          <Icon path={mdiPlus} size="20px" />
          &nbsp; Manual Order
        </Button>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        maxWidth="1024px"
        gap={2}
        mt={6}
      >
        <Box className="filters_container">
          <label>Rider</label>
          <ControlledSelectBox
            control={control}
            name={"riderIds"}
            placeholder={"Select a Rider"}
            options={[
              {
                id: undefined,
                text: "All",
                lastName: "",
              },
              ...riders.items,
            ].map((rider) => ({
              value: rider.id,
              label: rider.text,
            }))}
            styles={{
              input: (base: any) => ({
                ...base,
                minWidth: 250,
                marginTop: 0,
              }),
              container: (base: any) => ({
                ...base,
                marginTop: 0,
              }),
            }}
            isLoading={isLoadingRiders}
            onMenuScrollToBottom={handleScrollRidersFilter}
            onInputChange={handleSearchRiders}
          />
        </Box>
        <Box className="filters_container">
          <label>Selected Date</label>
          <DatePicker
            className="reactDatePicker"
            {...(workDate
              ? {
                  selected: new Date(workDate as any),
                }
              : {})}
            onChange={onChangeWorkDate}
            dateFormat="MM/dd/yyyy"
            timeIntervals={15}
            timeFormat="HH:mm"
            isClearable
            clearButtonClassName={"remove__workDate"}
            clearButtonTitle={"Clear"}
          />
        </Box>
        <Box className="filters_container">
          <label>Groups</label>
          <FilterDropDown
            hasLabel={false}
            filterBoxTitle="Group(s)"
            data={fetchGroupsListAsync.data || []}
            defaultSelectedValues={toArray(queryParams["groupIds"])}
            onChange={onChangeGroups}
          />
        </Box>
        <Box className="filters_container" ml={2}>
          <label>Order Type</label>
          <Select
            bgColor="#fff"
            value={queryParams["pickupType"]}
            onChange={(event) =>
              onChangePickupType(event.target.value as IOrderType)
            }
          >
            <option value={IOrderType.None}>ALL</option>
            <option value={IOrderType.ByRider}>Self Pickup Orders</option>
            <option value={IOrderType.ByThirdParty}>Third Party Orders</option>
            <option value={IOrderType.ByAdmin}>Admin Orders</option>
          </Select>
        </Box>
        <Box className="filters_container" ml={2}>
          <label>Pickup Type</label>
          <Select
            bg="#fff"
            disabled={queryParams["pickupType"] !== IOrderType.ByAdmin}
            value={queryParams["orderType"]}
            onChange={(event) => onChangeOrderType(event.target.value)}
          >
            <option value="false">All Admin Orders</option>
            <option value="true">Recent Admin Orders</option>
          </Select>
        </Box>
        <Box>
          <Button
            bgColor="#FEE4BA"
            minWidth="100px"
            ml="2px"
            mt={6}
            onClick={() => handleSearch()}
          >
            Search
          </Button>
        </Box>
      </Box>
      <Box height="5px" mb="1">
        {fetchOrderHistoriesAsync.isLoading && <HorizontalLoading />}
      </Box>
      <Box className="table_container" mt={3}>
        {!showNoResultFound ? (
          <Table
            variant="simple"
            colorScheme="blackAlpha"
            size="md"
            boxShadow="md"
            backgroundColor="white"
            borderRadius="md"
          >
            <Thead>
              <Tr>
                <Th>{t("fields.orderId")}</Th>
                <Th>{t("fields.orderValue")} (KD)</Th>
                <Th>{t("fields.groupId")}</Th>
                <Th>{t("fields.zoneName")}</Th>
                <Th>{t("fields.pending")}</Th>
                <Th>{t("fields.accepted")}</Th>
                <Th>{t("fields.pickedUp")}</Th>
                <Th>{t("fields.delivered")}</Th>
                <Th>{t("fields.action")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {fetchOrderHistoriesAsync.data?.map((orderItem) => {
                return (
                  <OrderHistoryItem
                    key={orderItem.id}
                    orderItem={orderItem}
                    setIsOpenAcceptedRiderModal={setIsOpenAcceptedRiderModal}
                    setIsOpenEligibleRiderModal={setIsOpenEligibleRiderModal}
                    setSelectedOrder={setSelectedOrder}
                  />
                );
              })}
            </Tbody>
          </Table>
        ) : (
          <NotFoundResult iconMaxWidth={250} />
        )}
      </Box>

      <Pagination
        entityTitle="Order"
        pageTotalCount={pageTotalCount}
        perPageItems={parseInt(queryParams["size"] || 10)}
        passPaginateValues={handlePassPaginateValues}
      />

      {/* Accepted Rider Modal */}
      <AcceptedRiders
        orderItem={selectedOrder}
        isOpen={isOpenAcceptedRiderModal}
        handleClose={() => setIsOpenAcceptedRiderModal(false)}
      />
      {/* Eligible RidersSelect Modal */}
      <EligibleRiders
        orderItem={selectedOrder}
        isOpen={isOpenEligibleRiderModal}
        handleClose={() => setIsOpenEligibleRiderModal(false)}
      />
    </>
  );
};

export default OrderHistoryList;
